:root {
	--spacing-s: 5px;
	--spacing-m: calc(var(--spacing-s) * 2);
	--spacing-l: calc(var(--spacing-s) * 3);
	--spacing-xl: calc(var(--spacing-s) * 4);
	--spacing-xxl: calc(var(--spacing-s) * 5);
	--spacing-sl: calc(var(--spacing-s) * 6);
	--spacing-ssl: calc(var(--spacing-s) * 8);
	--spacing-sssl: calc(var(--spacing-s) * 10);
	--gray-0: #ffffff;
	--gray-1: #f5f6fa;
	--gray-2: #dcdde1;
	--gray-3: #7f8fa6;
	--gray-4: #718093;
	--gray-5: #353b48;
	--gray-6: #2f3640;
	--red-1: #e84118;
	--red-2: #c23616;
	--orange: #fe7a50;
	--pink-1: #f8d7da;
	--pink-2: #f5c6cb;
	--blue-1: #00a8ff;
	--blue-2: #0097e6;
	--indigo-1: #192a56;
	--green-1: #4cd137;
	--green-2: #c3e6cb;
	--green-3: #d4edda;
	--yellow-1: #eccd72;
	--border-radius: 3px;
	--font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
}

* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
}

img,
video {
	height: auto;
	max-width: 100%;
}

ul {
	margin: 0;
	padding: 0;
}

ul li {
	list-style: none;
}

a {
	cursor: pointer;
}

p:empty {
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: var(--gray-2);
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: var(--gray-3);
}

input[type='range'] {
	-webkit-appearance: none;
	height: 5px;
	border-radius: 5px;
	background: var(--gray-2);
	outline: none;
	opacity: 0.7;
	transition: opacity 0.2s;
	margin: 0 var(--spacing-xxl);
}

::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--blue-2);
	cursor: pointer;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

::-moz-range-thumb {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--gray-0);
	cursor: pointer;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

::placeholder {
	color: var(--gray-3);
}

/* This should not be removed because of accessibility issues but it was requested*/

button:focus {
	outline: none;
}

/* Overwrite plugins */

.DayPicker {
	font-size: 13px;
}

.InputFromTo {
	display: inline-flex;
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
	border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
	width: 450px;
}

.InputFromTo-to .DayPickerInput-Overlay {
	left: -265px;
}

.DayPickerInput-Overlay {
	left: -179px;
	top: 15px;
}

.DayPickerInput input {
	width: 80px;
	text-align: center;
	border: none;
	font-size: 14px;
}

.DayPickerInput input::placeholder {
	color: var(--gray-5);
}
