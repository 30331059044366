html,
body {
	overflow: hidden;
}

.hello-list,
.main-view {
	height: 100vh;
	overflow: auto;
}

.app-wrapper {
	display: flex;
	flex-direction: column;
}

.app-wrapper > div {
	background: var(--gray-0);
}

.collapsed-second-column {
	grid-template-columns: 0 3fr !important;
}

.collapsed-second-column aside {
	padding: 0;
}

.collapse-second-column {
	position: absolute;
	top: calc(var(--spacing-xxl) + 2px);
	left: var(--spacing-m);
	background: var(--gray-0);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	font-size: 0;
	border-radius: var(--border-radius);
	height: 25px;
	width: 25px;
	align-items: center;
	justify-content: center;
}

.collapse-second-column:hover {
	background: var(--blue-2);
}

.collapse-second-column:hover i {
	color: var(--gray-0);
}

.collapse-second-column i {
	color: var(--gray-4);
	position: relative;
	left: -1px;
}

.collapsed-second-column ~ .collapse-second-column {
	left: 75px;
}

.main-view .collapse-second-column {
	display: none;
}

.main-view.monitoring-view .collapse-second-column {
	display: block;
}

.main-view {
	text-align: center;
	display: flex;
	flex-direction: column;
	background: var(--gray-1);
	position: relative;
}

.main-view > section {
	display: flex;
	justify-content: center;
	height: 100%;
	padding-bottom: 55px;
	overflow: auto;
	padding: 0 var(--spacing-sl);
}

.main-view.monitoring-view > div > header.header {
	display: flex;
	align-items: center;
	padding: 0 var(--spacing-m) 0 var(--spacing-sssl);
	position: relative;
	margin-bottom: var(--spacing-xxl);
	height: 80px;
}

.main-view.monitoring-view > section {
	padding: 0 var(--spacing-m);
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin-top: -30px;
}

.main-view .breadcrumb + section {
	padding-bottom: 50px;
}

.main-view .room {
	width: 320px;
	max-width: 100%;
}

.main-view .hello-device-description h2 {
	margin: 0 0 var(--spacing-s);
	font-weight: normal;
}

.main-view .hello-device-description p {
	margin: 0 0 var(--spacing-sl);
	color: var(--gray-4);
}

.feeds-0 {
	display: none !important;
}

.hello-list {
	padding: 0 var(--spacing-xxl);
	overflow-x: hidden;
}

.hello-list header {
	padding: var(--spacing-xxl) 0;
	display: flex;
	align-items: center;
}

.hello-list header > div {
	margin-left: auto;
}

.hello-list header > span {
	word-break: break-word;
}

.hello-list a {
	text-decoration: none;
}

.hello-list .dropdown > a i {
	margin: 0;
}

.hello-list .tree a:hover {
	font-weight: 500;
}

.hello-list > div > .tree {
	padding: 0;
	margin-bottom: var(--spacing-s);
}

.hello-list header > div {
	display: flex;
	align-items: center;
}

.hello-list header > div span {
	flex: 1;
}

.navigation {
	display: flex;
	align-items: center;
}

.navigation .user {
	margin-left: var(--spacing-s);
}

.navigation .user a {
	text-align: left;
}

.navigation .user p {
	margin: 0;
	padding: 0;
	line-height: 17px;
	font-size: 13px;
}

.navigation .user p:last-of-type {
	font-size: 12px;
	color: var(--gray-4);
}

.navigation .user p:last-of-type span {
	padding: 0 var(--spacing-s);
}

.navigation .user p:last-of-type i {
	font-size: 8px;
	margin: 0;
}

.navigation > div:first-of-type,
.navigation .dropdown a > i {
	margin-right: var(--spacing-s);
}

.navigation .dropdown.user > a img {
	margin-right: var(--spacing-s);
}

.navigation .dropdown.user > a > i {
	margin-right: -5px;
}

.navigation .badge {
	display: flex;
	align-items: center;
}

.navigation .badge > i {
	top: 0;
}

.call-view {
	background: #1376bc;
}

.call-view main video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.call-view .call-view-video {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.call-view-video img {
	border-radius: 50%;
	width: 100%;
	background-color: #0153b6;
	animation: pulse 2s infinite;
	user-select: none;
}

.call-view + .build-number {
	display: none;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}
}

.call-view .call-view__footer {
	position: absolute;
	bottom: -100px;
	left: 0;
	padding: var(--spacing-l) var(--spacing-m);
	background: var(--gray-0);
	width: 100%;
	min-height: 80px;
	transition: all 0.3s ease-in;
	background: white;
	color: black;
}

.call-view__footer--desc h3 {
	display: flex;
	align-items: center;
	font-size: 16px;
	white-space: nowrap;
}

.call-view__footer--desc h3 span:first-of-type {
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: var(--spacing-s);
	padding-bottom: 2px;
}

.call-view__footer--desc h3 span {
	cursor: pointer;
	position: relative;
	top: 2px;
}

.call-view__footer--desc h3 span i {
	font-size: 16px;
}

.call-view__footer--desc h3::before {
	width: auto;
	white-space: nowrap;
	text-align: left;
	font-size: 10px;
	max-width: initial;
	bottom: 30%;
	opacity: 0.75 !important;
	padding: 2px;
	left: 0;
	right: auto;
	transform: none;
	transition-delay: 0.5s;
}

.call-view__footer--desc h3::after {
	display: none;
}

.call-view:hover .call-view__footer {
	bottom: 0;
}

.call-view__footer--desc {
	font-size: 12px;
}

.call-view__footer > div:nth-child(1) h3,
.call-view__footer > div:nth-child(1) p {
	margin: 0;
}

.call-view__footer > div:nth-child(1) h3 {
}

.call-view__footer > div:nth-child(2) {
	text-align: center;
}

.call-view__footer > div:nth-child(3) {
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.call-view__footer > div:nth-child(2) .call-button:last-of-type,
.call-view__footer > div:nth-child(3) .call-button:last-of-type {
	margin: 0;
}

.call-view__footer > div:nth-child(3) .call-button:last-of-type::before {
	left: -20px;
}

.call-view__footer a {
	font-size: 0;
}

.call-view aside {
	position: absolute;
	top: var(--spacing-xl);
	right: var(--spacing-xl);
}

.call-view.patient-view aside > a {
	margin: 0;
}

.call-view.patient-view .tilt_container {
	bottom: auto;
	left: auto;
	top: 50px;
	left: 0;
}

.call-view.patient-view .call-stats {
	top: var(--spacing-xl);
	right: var(--spacing-xl);
	left: auto;
	background: #fff;
	padding: 4px;
	border-radius: 100%;
	font-size: 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.call-view.patient-view .call-stats > div {
	top: 0;
	bottom: auto;
}

.call-view .volume-dropdown {
	position: absolute;
	transform: rotate(-90deg);
	right: -85px;
	bottom: 130px;
	background: var(--gray-0);
	padding: var(--spacing-sl) var(--spacing-xl);
	border-radius: var(--border-radius);
	box-shadow: 6px 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}

.call-view .volume-dropdown input {
	margin: var(--spacing-m);
}

.call-view .volume-dropdown i,
.call-view .volume-dropdown span {
	color: var(--gray-4);
	transform: rotate(90deg);
}

.call-view .volume-dropdown i {
	cursor: pointer;
}

.call-view .volume-dropdown span {
	font-size: 14px;
	width: 30px;
	text-align: center;
}

.call-view .volume-dropdown.cec-volume-dropdown {
	bottom: 60px;
	right: 0;
	padding: 5px;
	transform: none;
	flex-direction: row;
}

.call-view .cec-volume-dropdown button {
	font-size: 0px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

.call-view .cec-volume-dropdown button:hover i {
	color: var(--blue-2);
}

.call-view .cec-volume-dropdown button {
	font-size: 0px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

.call-view .cec-volume-dropdown button:hover i {
	color: var(--blue-2);
}

.call-view .cec-volume-dropdown i,
.call-view .cec-volume-dropdown span {
	width: auto;
	transform: none;
}

.call-view.is-screensharing .call-view-video video {
	object-fit: contain;
}

.room {
	padding-bottom: 65px;
}

.organization {
	text-align: left;
}

.organization h2 {
	font-weight: 400;
	margin: 0;
}

.organization h3,
.users h3 {
	display: flex;
	align-items: center;
}

.organization h3 span,
.users h3 span {
	flex: 1;
}

.organization h3 a {
	justify-self: flex-end;
}

.organization h3 > a,
.users h3 > a {
	margin-right: 0;
	margin-left: var(--spacing-s);
}

.organization h3 > .button.medium + .button.medium,
.users h3 > .button.medium + .button.medium {
	margin-right: 0;
}

.users .button,
.organization .button {
	float: right;
}

.users header > div:last-of-type,
.users main > div > div:last-of-type {
	flex: 2;
}

.users header > div:last-of-type {
	text-align: center;
}

.users.device-details header > div:last-of-type {
	text-align: left;
}

.users.device-details .tabs li > a {
	padding: var(--spacing-l);
}

.users.device-details .dropdown {
	padding: 0;
}

.users.device-details .dropdown > a {
	padding: 0 0 0 var(--spacing-s);
}

.wrapped .button {
	margin-right: var(--spacing-s);
}

.wrapped > i:first-of-type {
	background: var(--blue-2);
}

.login {
	background: var(--indigo-1);
	height: 100vh;
	overflow: auto;
	padding: 20px;
}

.login .login__wrapper > div:first-of-type {
	margin: 0 auto;
	margin-bottom: var(--spacing-xxl);
}

.login .login-form-wrapper {
	background: var(--gray-0);
	padding: var(--spacing-ssl);
	border-radius: var(--border-radius);
}

.login h2 {
	text-align: center;
	margin: 0 0 var(--spacing-l);
	padding: 0;
}

.login small {
	float: right;
	padding: var(--spacing-m) 0 var(--spacing-l);
}

.login small a {
	color: var(--blue-2);
}

.create-hs {
	overflow: auto;
	background: var(--gray-1);
}

.create-hs form {
	width: 450px;
	padding: var(--spacing-sssl);
	background: var(--gray-0);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: var(--border-radius);
}

.create-hs .create-hs__add {
	text-align: right;
	margin-bottom: var(--spacing-m);
}

.create-hs p {
	margin-bottom: var(--spacing-m);
	font-weight: 500;
	font-size: 14px;
	padding: 0;
}

.create-hs .transparent {
	padding-right: 0;
}

.users,
.organization {
	text-align: left;
	width: 100%;
}

.users > h2 {
	margin-bottom: var(--spacing-xl);
}

.users.device-details div {
	text-align: left;
}

.users-view h3,
.call-logs-view h3,
.pools-view h3 {
	margin-bottom: var(--spacing-xl);
	text-align: left;
	width: 100%;
}

.call-logs-view .InputFromTo {
	height: 38px;
	display: flex;
	align-items: center;
	padding-right: 8px;
	padding-left: 8px;
	border: 1px solid #cccccc;
	border-radius: 3px;
}

.call-logs-view .table__slot .material-icons-outlined {
	position: relative;
	right: 6px;
}

.modal form > .hs-list:first-of-type a {
	position: relative;
	top: 5px;
}

.hs-list,
.hs-list ul {
	display: flex;
}

.hs-list {
	align-items: center;
	margin-bottom: var(--spacing-s);
	position: relative;
}

.hs-list .circle-loader {
	position: absolute;
	z-index: 1;
	right: 45px;
	width: 15px;
	height: 15px;
	border: 2px solid var(--blue-2);
	border-bottom-color: transparent;
	border-right-color: transparent;
}

.hs-list ul i {
	color: var(--gray-4);
	font-size: 12px;
	padding: var(--spacing-s);
	border-radius: var(--border-radius);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.hs-list ul li:first-of-type {
	margin-right: var(--spacing-s);
}

.hs-list input {
	flex: 1;
	font-size: 14px;
	margin-right: 0;
	padding: var(--spacing-m);
	padding-right: var(--spacing-xxl);
	border: none;
	border-bottom: 1px solid var(--gray-2);
}

.hs-list input:focus {
	border: none;
	outline: none;
	border-bottom: 1px solid var(--blue-2);
}

.hs-list .input {
	width: 100%;
}

.hs-list .edit {
	background: var(--gray-4);
	color: #fff;
}

.hs-list .delete {
	background: var(--red-1);
	border: 1px solid var(--red-1);
	color: var(--gray-0);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	position: absolute;
	right: 0;
	top: 7px;
	padding: 2px;
	border-radius: 3px;
}

.hs-list + p a {
	color: var(--blue-2);
	font-size: 14px;
	text-decoration: none;
}

.hs-list + p,
.hs-list small {
	color: var(--red-1);
	font-size: 14px;
}

.edit-hs .modal__actions > a:first-of-type {
	margin: 0;
}

.edit-hs .alert {
	left: auto;
}

.region-actions {
	display: flex;
	align-items: center;
}

.region-actions p {
	margin-left: auto;
	font-size: 14px;
	margin-bottom: 0;
	padding: 0;
}

.region-actions p a {
	color: var(--blue-2);
}

.active-badge span {
	background: var(--green-1);
	color: var(--gray-0);
	padding: calc(var(--spacing-s) / 2) var(--spacing-m);
	border-radius: var(--border-radius);
	font-size: 12px;
}

.offline-badge span {
	background: var(--gray-1);
	color: var(--gray-4);
	padding: calc(var(--spacing-s) / 2) var(--spacing-m);
	border-radius: var(--border-radius);
	font-size: 12px;
}

.boxed-icon {
	background: var(--gray-4);
	border-radius: var(--border-radius);
	font-size: 16px;
	padding: var(--spacing-s);
	cursor: pointer;
	color: var(--gray-0);
	float: right;
}

.boxed-icon.pool-icon {
	background: var(--yellow-1);
}

.create-hospital {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: var(--spacing-m);
	background: none;
	border: none;
}

.create-hospital > div {
	font-size: 0;
}

.create-hospital span {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.create-hospital span,
.create-hospital i {
	color: var(--blue-2);
}

.create-hospital p:hover {
	font-weight: 500;
}

.create-hospital i {
	font-size: 18px;
	margin-right: var(--spacing-s);
}

.wrapped {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0px !important;
}

.wrapped.align {
	justify-content: flex-end;
}

.wrapped i {
	margin-bottom: 2px;
	margin-left: 5px;
}

.wrapped > i:last-of-type {
	background: var(--red-1);
}
.wrapped > div:last-of-type > i {
	background: var(--red-1);
}

.close-feed {
	display: inline-flex;
	background: var(--gray-0);
	border-radius: 30px;
	padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-s);
	font-size: 14px;
	color: var(--gray-5);
	align-items: center;
	text-decoration: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-feed i {
	margin-right: calc(var(--spacing-s) / 2);
}

.patient-view-control {
	position: fixed;
	top: var(--spacing-xl);
	left: var(--spacing-xl);
}

.user-status {
	padding-top: 0;
}

.user-status li:first-of-type {
	padding: 0;
}

.notification-group {
	display: flex;
	align-items: center;
	text-align: left;
	width: 250px;
}

.notification-group img {
	margin-right: var(--spacing-m);
}

.notification-group p {
	margin: 0;
	padding: 0;
	line-height: 18px;
	color: var(--gray-5);
	white-space: normal;
}

.notification-group p small {
	color: var(--gray-4);
}

.notifications-list {
	height: 315px;
	overflow: auto;
}

.notifications-list > li:hover * {
	color: #fff;
}

.notifications-wrapper {
	position: relative;
}

.notifications-wrapper .badge {
	position: absolute;
	top: -5px;
	right: 2px;
	z-index: 99;
}

.role-wrapper {
	display: inline-flex;
	align-items: center;
	position: relative;
	padding: var(--spacing-s);
	cursor: pointer;
}

.switch-camera,
.switch-camera-hello {
	position: absolute;
	left: 80px;
	top: var(--spacing-xl);
}

.switch-camera-hello {
	left: 160px;
}

.modal form > div > .tree {
	padding: 0;
}

.night-vision i {
	transform: rotate(180deg);
}

.monitoring-list .hide ~ .action i {
	opacity: 1;
	margin: 0;
}

.page-loader {
	position: fixed;
	left: 0;
	top: 0;
}

.registration-form .invalid-invite {
	text-align: center;
}

.registration-form .invalid-invite i {
	font-size: 60px;
	padding-bottom: 15px;
}

.div-container {
	display: flex;
	align-items: center;
}

.bold {
	font-weight: 600;
}

.btn-disabled,
.btn-disabled:hover,
.btn-disabled:focus,
.disabled-zoom {
	opacity: 0.25;
	cursor: initial;
	pointer-events: none;
}

.disabled-zoom {
	pointer-events: none;
}

.monitoring-feeds > div {
	min-width: 0;
}

.monitoring-feeds {
	height: calc(100vh - 115px);
}

.monitoring-feeds.feeds-1 {
	max-height: calc(100vh - 115px);
}

.feeds-1 > div {
	height: calc(100vh - 115px);
}

.feeds-2 > div,
.feeds-3 > div,
.feeds-4 > div {
	height: calc(50vh - 60px);
}

.monitoring-feeds-1,
.monitoring-feeds-2 {
	align-content: center;
}

.monitoring-feeds.feeds-1 .feed-is-connecting {
	height: calc(100vh - 115px);
}

.feeds-2,
.feeds-3,
.feeds-4 {
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr !important;
	justify-content: center;
	align-content: center;
}

.feeds-3,
.feeds-4 {
	grid-template-rows: repeat(2, 1fr) !important;
	grid-template-columns: repeat(2, 1fr);
}

.collapsed-second-column .feeds-3,
.collapse-second-column .feeds-4 {
	grid-template-columns: repeat(3, 1fr);
}

.feeds-5,
.feeds-6 {
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr) !important;
}

.feeds-5 > div,
.feeds-6 > div {
	height: auto;
}

.feeds-7,
.feeds-8,
.feeds-9 {
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr) !important;
}

.feeds-10,
.feeds-11,
.feeds-12 {
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(3, 1fr) !important;
}

.feeds-7 > div,
.feeds-8 > div,
.feeds-9 > div,
.feeds-10 > div,
.feeds-11 > div,
.feeds-12 > div {
	height: calc(33vh - 35px);
}

/* .feeds-10 > div,
.feeds-11 > div,
.feeds-12 > div {
	height: calc(25vh - 10px);
} */

.feeds-13,
.feeds-14,
.feeds-15 {
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(4, 1fr) !important;
}

.feeds-16 {
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(4, 1fr) !important;
}

.zoomed-feed.feeds-16 {
	grid-template-rows: repeat(4, 1fr) !important;
}

.feeds-13 > div,
.feeds-14 > div,
.feeds-15 > div,
.feeds-16 > div {
	height: calc(25vh - 33px);
}

.zoomed-feed {
	grid-template-columns: repeat(4, 1fr);
	position: relative;
}

.zoomed-feed .patient-view-control,
.feeds-1 .patient-view-control {
	position: absolute;
	top: 40px;
	left: var(--spacing-m);
}

.zoomed-feed > div {
	height: auto;
	overflow: hidden;
}

.zoomed-feed > div.expand-feed {
	grid-column: 1/5;
	grid-row-start: 1;
}

.zoomed-feed > div:not(.expand-feed) video {
	height: calc(100% - 35px);
}

.edit-member-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.edit-member-form .profile-picture {
	width: 64px;
	height: 64px;
	margin-right: 0;
}

.zoomed-feed.feeds-2,
.zoomed-feed.feeds-3,
.zoomed-feed.feeds-4,
.zoomed-feed.feeds-5,
.zoomed-feed.feeds-6,
.zoomed-feed.feeds-7,
.zoomed-feed.feeds-8,
.zoomed-feed.feeds-9,
.zoomed-feed.feeds-10,
.zoomed-feed.feeds-11,
.zoomed-feed.feeds-12,
.zoomed-feed.feeds-13,
.zoomed-feed.feeds-14 {
	grid-template-rows: repeat(4, 1fr) !important;
	grid-template-columns: repeat(7, 1fr) !important;
}

.zoomed-feed.feeds-15,
.zoomed-feed.feeds-16 {
	grid-template-rows: repeat(5, 1fr) !important;
	grid-template-columns: repeat(7, 1fr) !important;
}

.zoomed-feed.feeds-2 .expand-feed,
.zoomed-feed.feeds-3 .expand-feed,
.zoomed-feed.feeds-4 .expand-feed,
.zoomed-feed.feeds-5 .expand-feed {
	grid-column-start: 1;
	grid-column-end: 7;
	grid-row-start: 1;
	grid-row-end: 5;
	height: auto;
}

.zoomed-feed.feeds-6 .expand-feed,
.zoomed-feed.feeds-6 .expand-feed,
.zoomed-feed.feeds-7 .expand-feed,
.zoomed-feed.feeds-8 .expand-feed,
.zoomed-feed.feeds-9 .expand-feed,
.zoomed-feed.feeds-10 .expand-feed,
.zoomed-feed.feeds-11 .expand-feed,
.zoomed-feed.feeds-12 .expand-feed,
.zoomed-feed.feeds-13 .expand-feed,
.zoomed-feed.feeds-14 .expand-feed {
	grid-column-start: 1;
	grid-column-end: 6;
	grid-row-start: 1;
	grid-row-end: 4;
	height: auto;
}

.zoomed-feed.feeds-15 .expand-feed,
.zoomed-feed.feeds-16 .expand-feed {
	grid-column-start: 1;
	grid-column-end: 6;
	grid-row-start: 1;
	grid-row-end: 5;
	height: auto;
}

.zoomed-feed > div:not(.expand-feed) header button {
	padding: 0;
}

.zoomed-feed > div:not(.expand-feed) button i {
	font-size: 16px;
}

.monitoring-feeds .feed-options-wrapper {
	display: flex;
}

.feed-dropdown li a > span {
	margin-right: var(--spacing-s);
}

.feed-dropdown li a > span + i {
	margin-left: auto;
	font-size: 16px;
}

.feeds-1 {
	position: relative;
}

.edit-hs .modal__actions > a:last-of-type {
	display: none;
}

.edit-hs .label {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: var(--spacing-m);
}

.feeds-limit .modal__actions > a:first-of-type {
	margin: 0;
}

.feeds-limit .modal__actions > a:last-of-type {
	display: none;
}

.member-error {
	text-align: center;
}

.member-error img {
	max-width: 500px;
}

.member-error .logout-link {
	cursor: pointer;
	text-decoration: underline;
}

.call-stats {
	position: absolute;
	top: 88px;
	left: 5px;
	z-index: 1;
}

.call-stats > a {
	display: inline-block;
	font-size: 0;
	cursor: pointer;
}

.call-stats > a i {
	font-size: 14px;
	border-radius: 100%;
	padding: 2px;
	color: white;
	background: var(--gray-4);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.call-stats > div {
	position: absolute;
	bottom: -4px;
	right: calc(100% + 10px);
	background: #fff;
	width: 200px;
	border-radius: 3px;
	padding: 10px;
	opacity: 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.call-stats > a:hover + div {
	opacity: 1;
}

.call-stats > div p {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.app-version * {
	padding: 0;
	margin: 0;
	line-height: 18px;
}

.app-version button {
	width: 100%;
	background: none;
	border: none;
	text-align: left;
}

.app-version button {
	color: var(--red-1);
	cursor: pointer;
}

.app-version button i {
	font-size: 15px;
	position: relative;
	top: 2px;
	left: -1px;
}

.app-version button:hover {
	text-decoration: underline;
}

.app-version small {
	font-size: 13px;
	color: var(--gray-3);
}

.app-version span {
	width: 100%;
	float: left;
}

.reconnecting > .alert.fixed {
	left: 50%;
	transform: translateX(-50%);
	bottom: auto;
	top: -80px;
}

.reconnecting > .alert.fixed.persist {
	background: rgba(0, 0, 0, 0.5);
	opacity: 1;
	visibility: visible;
	top: 15px;
	right: auto;
	bottom: auto;
	margin: 0;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	align-items: center;
	padding: 10px 16px;
}

.reconnecting > .alert.fixed.persist span {
	color: white;
}

.reset-camera {
	cursor: pointer;
	font-size: 16px;
	color: var(--gray-4);
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 100%;
}

.reset-camera:hover {
	background: rgba(0, 0, 0, 0.1);
}

.reset-camera.disabled-reset {
	opacity: 0.5;
	pointer-events: none;
}

.disabled {
	opacity: 0.75;
	pointer-events: none;
}

.availability-dropdown .list-group li:first-of-type i {
	color: var(--green-1);
}

.availability-dropdown .list-group li:last-of-type i {
	color: var(--red-1);
}

.recover-password {
	height: 350px;
}

.recover-password .link,
.validate-recover-password .link {
	cursor: pointer;
	text-decoration: underline;
}

.recover-password .warning-message,
.validate-recover-password .warning-message {
	border: solid 1px grey;
	box-shadow: none !important;
	border: 1px solid #ddd;
	background-color: #f4f4f4;
	font-size: 14px;
	color: var(--gray-5);
	padding: 20px;
	display: flex;
}

.recover-password .warning-message span.material-icons,
.validate-recover-password .warning-message span.material-icons {
	font-size: 40px;
	padding-right: 10px;
	padding-top: 6px;
}

.banyan-help {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	background: #192a56;
}

.banyan-help header {
	padding: 36px 36px 0 36px;
	width: 100%;
}

.banyan-help header img {
	width: 300px;
}

.banyan-help main {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: url(https://solprodmediastorage.blob.core.windows.net:443/static-assets/banyan/world-map-white.svg) center;
	text-align: center;
}

.banyan-help main div {
	width: 300px;
}

.banyan-help main h1 {
	font-size: 28px;
}

.banyan-help main h1,
.banyan-help main p {
	color: white;
}

.edit-sector-modal input {
	margin-bottom: var(--spacing-s);
}

.edit-sector-modal input + small {
	color: var(--red-1);
}

.edit-sector-modal input + small::first-letter {
	text-transform: uppercase;
}

.edit-sector-modal .input ~ main {
	margin-bottom: var(--spacing-xxl);
	display: flex;
	align-items: center;
	font-size: 14px;
	color: var(--gray-5);
}

.edit-sector-modal .input ~ main i {
	color: var(--gray-4);
	font-size: 18px;
	margin-right: var(--spacing-s);
}

.centered-modal {
	z-index: 1000;
}
.centered-modal .modal__wrapper {
	border-radius: 3px;
}
.centered-modal .modal__content {
	padding: 15px;
}
.centered-modal .button {
	padding: var(--spacing-m) var(--spacing-xl);
}

.crop-modal .modal__content > div {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.crop-modal {
	pointer-events: none;
}

.crop-modal .modal__wrapper {
	pointer-events: auto;
}

.hospital-logo img {
	max-height: 80px;
}

.hospital-logo p {
	font-weight: 500;
	margin-bottom: var(--spacing-m);
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.hospital-logo input {
	padding: var(--spacing-l) 0;
}

.hospital-logo input + p {
	color: var(--gray-4);
	font-weight: normal;
	margin-bottom: var(--spacing-m);
}

.hide-col .participant {
	display: none;
}

.toggle-right-column {
	position: absolute;
	top: 0;
	left: -35px;
	border: none;
	border-radius: var(--border-radius);
	background: white;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	cursor: pointer;
}

.configuration-modal .input small {
	color: var(--red-1);
}

.fixed-tooltip {
	display: none;
	position: fixed;
	z-index: 1;
	padding: 5px 10px;
	color: var(--gray-0);
	white-space: normal;
	font-size: 12px;
	box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
	border-radius: var(--border-radius);
	font-weight: 500;
	text-transform: none;
	font-family: var(--font-family);
	background: var(--gray-5);
	text-align: center;
	word-break: break-word;
	transform: translateX(calc(-50% + 5px)) translateY(calc(-100% - 8px));
}

.fixed-tooltip::before {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #333;
}

.monitoring-precautions {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.monitoring-precautions > div {
	width: 20%;
	display: flex;
}

.monitoring-precautions > div > i {
	font-size: 16px;
}

.monitoring-precautions > div > span {
	margin-left: var(--spacing-s);
	font-size: 12px;
	text-align: left;
}

.list-group.feed-dropdown li a i {
	margin-right: 0;
}

.list-group.feed-dropdown li a span {
	margin: 0 var(--spacing-s);
}

.device-config .table main > div > div:last-of-type .wrapped {
	justify-content: flex-end;
}

.device-config .table main > div > div:last-of-type .wrapped span {
	margin-right: var(--spacing-s);
}

.input-el {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: var(--spacing-sl);
}

.input-el > label {
	display: block;
	margin-bottom: var(--spacing-m);
	color: var(--gray-5);
}

.input-el > label + p {
	padding: 0;
	color: var(--gray-4);
	margin-bottom: var(--spacing-m);
}

.input-el > main {
	display: flex;
	align-items: center;
}

.input-el > main > input {
	margin: 0 var(--spacing-s) 0 var(--spacing-xl);
}

.input-el > main > label {
	color: var(--gray-5);
}

.input-el > main > input:first-of-type {
	margin-left: 0;
}

.input-el > div {
	display: flex;
	align-items: center;
}

.input-el input[type='range'] {
	width: 100%;
	margin: var(--spacing-m) var(--spacing-l) var(--spacing-m) 0;
}

.input-el small {
	color: var(--red-1);
	font-size: 14px;
	display: block;
	margin: var(--spacing-m) 0;
	font-weight: 400;
}

.font-bold {
	font-weight: 500 !important;
}

.kpi-wrapper > div {
	margin-top: var(--spacing-l);
	border: 1px solid var(--gray-2);
	padding: var(--spacing-l);
	border-radius: var(--spacing-s);
	font-size: 14px;
	color: var(--gray-4);
}

.kpi-wrapper > div > p {
	margin: 0;
	padding: 0;
	font-size: 16px;
}

.dashboard-wrapper canvas {
	max-width: 100% !important;
}

.alarm-btn {
	position: absolute;
	left: var(--spacing-m);
}

li .alarm-btn[data-position='top'],
li .alarm-btn[data-position='top'] i {
	cursor: pointer;
}

.alarm-btn[data-position='right']::before {
	width: 60px;
}

.alarm-blinking {
	background-color: rgba(255, 0, 0, 0.6);
	animation: fallDetectionBlink 1s linear infinite;
	-webkit-animation-direction: alternate-reverse;
	top: 30px;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

@keyframes fallDetectionBlink {
	from {
		opacity: 0.33;
	}

	to {
		opacity: 0.75;
	}
}
