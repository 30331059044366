.left-navigation {
	padding: 15px;
	display: flex;
	flex-direction: column;
	text-align: center;
	position: relative;
	background: var(--indigo-1);
}

.left-navigation li {
	margin-bottom: var(--spacing-xl);
	padding: var(--spacing-s);
	user-select: none;
	text-align: center;
}

.left-navigation li a.active i,
.left-navigation li a.active span,
.left-navigation li a:hover i,
.left-navigation li a:hover span {
	color: var(--blue-1);
	border-radius: 3px;
}

.left-navigation li:last-of-type {
	margin-top: auto;
	margin-bottom: 0;
}

.left-navigation li:last-of-type i {
	transform: rotate(90deg);
	margin: 0;
}

.left-navigation li a {
	color: var(--gray-5);
	display: flex;
	align-items: center;
	text-decoration: none;
	justify-content: center;
}

.left-navigation li i {
	color: var(--gray-0);
}

.left-navigation li.logo {
	padding: 0;
	pointer-events: none;
}

.left-navigation li.logo a img {
	border-radius: 3px;
	max-width: 45px;
}

.left-navigation li span {
	color: var(--gray-0);
	text-decoration: none;
	display: none;
	font-size: 14px;
}

.left-navigation.expanded li a {
	justify-content: flex-start;
}

.left-navigation.expanded li span {
	display: block;
	white-space: nowrap;
}

.left-navigation.expanded li i {
	margin-right: var(--spacing-m);
}

.navigation ul {
	display: flex;
}

.header {
	display: flex;
	align-items: center;
	padding: var(--spacing-xxl) var(--spacing-sssl);
	position: relative;
	margin-bottom: var(--spacing-xxl);
}

.header > div:first-of-type a {
	display: flex;
}

.header > div:first-of-type a img {
	padding: 3px;
}

.header > div:last-of-type {
	margin-left: auto;
}

.dropdown {
	display: inline-flex;
	align-items: center;
	position: relative;
	padding: var(--spacing-s);
	border-radius: var(--border-radius);
}

.dropdown.dropdown--reverse a {
	flex-direction: row-reverse;
}

.dropdown > a {
	text-decoration: none;
	color: var(--gray-5);
	display: flex;
	align-items: center;
	font-size: 14px;
	cursor: pointer;
	user-select: none;
}

.dropdown > a i {
	margin-right: var(--spacing-m);
	color: var(--gray-5);
	font-size: 22px;
}

.dropdown > a img {
	height: 25px;
	width: 25px;
	border-radius: 100%;
	margin-right: var(--spacing-m);
}

.dropdown > a img ~ i {
	margin: 0;
}

.dropdown.bottom.dropdown--open .dropdown__items {
	opacity: 1;
	transform: scale(1);
	visibility: visible;
	z-index: 99;
	padding: initial;
}

.dropdown.bottom .dropdown__items {
	position: absolute;
	top: calc(100% + 5px);
	right: 0;
	background: var(--gray-0);
	opacity: 0;
	visibility: hidden;
	transform: scale(0.95);
	transition: 0.1s;
	transform-origin: top right;
}

.list-group {
	border-radius: var(--border-radius);
	flex-direction: column;
	padding: var(--spacing-m) 0;
	position: relative;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	background: var(--gray-0);
	border-radius: var(--border-radius);
}

.list-group li {
	margin: 0;
	position: relative;
}

.list-group li:hover {
	background: var(--blue-1);
}

.list-group li:hover > a,
.list-group li:hover > a > i {
	color: #fff;
}

.list-group li a > .list-group {
	opacity: 0;
}

.list-group li:hover a > .list-group {
	opacity: 1;
}

.list-group li a {
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-right: var(--spacing-m);
	cursor: pointer;
	color: var(--gray-5);
	font-size: 14px;
	text-decoration: none;
	padding: var(--spacing-m) var(--spacing-xl);
}

.list-group li a i {
	margin-right: var(--spacing-m);
	color: var(--gray-5);
	font-size: 18px;
}

.list-group .list-group {
	position: absolute;
	right: 100%;
	top: 0;
}

.hello-feature-block {
	padding: var(--spacing-sl) var(--spacing-xxl);
	border-radius: var(--border-radius);
	text-align: center;
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	cursor: pointer;
	transition: 0.15s;
	user-select: none;
	background: var(--gray-0);
	position: relative;
}

.hello-feature-block:hover,
.hello-feature-block:active {
	background: var(--blue-2);
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	transform: translateY(-5px);
}

.hello-feature-block:active {
	transform: translateY(-2px);
}

.hello-feature-block:hover i,
.hello-feature-block:hover p {
	color: var(--gray-0);
}

.hello-feature-block i {
	margin-bottom: var(--spacing-m);
	color: var(--blue-2);
}

.hello-feature-block p {
	margin: 0;
	color: var(--gray-4);
}

.hello-feature-block .info {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 0;
}

.hello-feature-block .info i {
	font-size: 16px;
}

.hello-feature-block .info::before {
	white-space: normal;
	width: 300px;
	padding: var(--spacing-m);
}

.breadcrumb {
	display: flex;
}

.breadcrumb li,
.breadcrumb li a {
	color: var(--gray-4);
	font-size: 15px;
	display: flex;
}

.breadcrumb li:not(:last-of-type)::after {
	content: 'keyboard_arrow_right';
	font-family: 'Material Icons';
	position: relative;
	top: 3px;
	margin-right: var(--spacing-s);
}

.breadcrumb li.active a {
	color: var(--indigo-1);
}

.breadcrumb li a {
	text-decoration: none;
}

.breadcrumb li a:hover {
	text-decoration: underline;
}

.badge {
	display: inline-block;
	font-size: 12px;
	padding: calc(var(--spacing-s) / 2) var(--spacing-m) !important;
	border-radius: var(--border-radius);
}

.badge.xsmall {
	padding: 0 var(--spacing-s) !important;
	font-size: 10px;
}

.badge.green {
	background: rgba(76, 209, 55, 0.25);
	color: var(--gray-4);
}

.badge.darkgreen {
	background-color: var(--green-1);
	color: var(--gray-0);
}

.badge.badge.darkgreen > i {
	color: var(--gray-0);
}

.badge.gray {
	background: var(--gray-1);
	color: var(--gray-4);
}

.badge.red {
	background: rgba(225, 0, 0, 0.25);
	color: var(--gray-4);
}

.badge.darkred {
	background: var(--red-1);
	color: var(--gray-0);
	margin-top: 3px;
	margin-left: 5px;
}

div.badge > i {
	margin: 0;
	font-size: 14px;
	position: relative;
	top: 2px;
}

.pool-devices-selection .tree {
	padding-right: 0;
}

.tree {
	padding-left: var(--spacing-l);
	font-size: 14px;
	color: var(--gray-5);
	width: 100%;
}

.tree > div {
	margin-bottom: var(--spacing-m);
}

.tree .tree__child ~ div:last-of-type {
	margin: 0;
}

.tree .tree__child > div {
	width: 100%;
	display: flex;
	align-items: center;
}

.tree .tree__child > div[data-tooltip]::before {
	max-width: 350px;
}

.tree.search-mode .tree__child.hidden + div {
	display: none;
}

.tree .tree__child:hover i:not(.hide) {
	opacity: 1;
}

.tree .action {
	float: right;
	font-size: 0;
}

.tree > div,
.tree > div:last-of-type {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.tree p {
	margin: 0;
	padding: 0;
	font-size: 12px;
	color: var(--red-1);
	position: relative;
}

.tree p::first-letter {
	text-transform: uppercase;
}

.tree > div + div {
	flex-direction: column;
	align-items: flex-start !important;
}

.tree i {
	cursor: pointer;
	font-size: 18px;
	user-select: none;
	margin-right: var(--spacing-s);
	color: var(--gray-4);
}

.tree i.hide {
	opacity: 0;
}

.tree .action i {
	opacity: 0;
	transition: 0.2s;
}

.tree .link {
	flex: 1;
	text-decoration: none;
	color: var(--gray-5);
	word-break: break-all;
}

.tree .link.unassignable {
	color: var(--gray-3);
	cursor: default !important;
}

.tree .selected {
	position: relative;
}

.tree .selected::before {
	content: '';
	position: absolute;
	top: -5px;
	left: -100%;
	width: 300%;
	background: #edeef3;
	height: calc(100% + 10px);
}

.tree .selected > div {
	position: relative;
}

.tree .tree__new {
	margin-left: 23px;
	display: flex;
	flex-direction: row;
	align-items: center !important;
}

.tree .tree__new ~ p {
	margin-top: var(--spacing-s);
	align-self: flex-start;
	left: 35px;
}

.units-wrapper {
	display: flex;
}

.units-wrapper > div {
	margin-right: 19px;
}

.tree .tree__new input {
	padding: var(--spacing-s);
	width: 100%;
	border: none;
	border-bottom: 1px solid var(--gray-2);
	font-size: 14px;
}

.tree .tree__new input:focus {
	outline: none;
	border-bottom: 1px solid var(--blue-2);
}

.tree .room-status {
	font-size: 10px;
	margin-left: var(--spacing-s);
	margin-right: 0;
	display: none;
}

.tree .room-status + .room-status {
	margin-left: var(--spacing-s);
}

.tree .room-status.online,
.tree .room-status.offline,
.tree .room-status.busy,
.tree .room-status.on-call {
	display: inline-block;
}

.tree .room-status.online {
	color: var(--green-1);
}

.tree .room-status.offline {
	color: var(--gray-3);
}

.tree .room-status.busy {
	color: var(--red-1);
}

.tree .room-status.on-call {
	color: var(--orange);
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	z-index: 999;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.modal .link {
	pointer-events: none;
}

.modal .assign-dropdown .link {
	pointer-events: initial;
}

.modal .modal__wrapper {
	transition: 0.3s;
	min-width: 580px;
	position: relative;
	background-color: #fff;
}

.modal__actions > a:nth-child(1) {
	margin-right: 10px;
}

.modal .modal__loader {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal.center .modal__wrapper {
	transform: translateY(100%);
	width: 500px;
}

.modal.center.show .modal__wrapper {
	transform: translateY(0);
}

.modal.right .modal__wrapper {
	transform: translateX(100%);
	width: 580px;
}

.modal.right.show .modal__wrapper {
	transform: translateX(0);
}

.modal.left {
	justify-content: flex-start;
}

.modal.left .modal__content {
	height: 100vh;
}

.modal.right {
	justify-content: flex-end;
}

.modal.right .modal__content {
	height: calc(100vh - 70px);
	overflow-y: auto;
	overflow-x: hidden;
}

.modal .modal__close {
	position: absolute;
	top: var(--spacing-l);
	right: var(--spacing-l);
}

.modal.center {
	justify-content: center;
	align-items: center;
}

.modal.center .modal__content {
	border-radius: var(--border-radius);
	text-align: left;
	max-height: 500px;
}

.modal.loading .modal__content::before {
	content: '';
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	background: var(--gray-0);
	z-index: 1;
}

.modal.loading .modal__content::after {
	content: '';
	border-radius: 100%;
	margin: 2px;
	border: 3px solid var(--blue-2);
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 25px;
	width: 25px;
	display: inline-block;
	animation: rotate 0.4s linear infinite;
	position: absolute;
	z-index: 1;
	top: calc(50% - 12px);
	left: calc(50% - 12px);
	transform: translate(-50%, -50%);
}

.modal.show {
	opacity: 1;
	visibility: visible;
}

.modal .modal__content {
	background: var(--gray-0);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
	text-align: left;
	max-height: 100vh;
	overflow: auto;
}

.modal.right .modal__wrapper {
	position: relative;
	max-height: 100vh;
}

.modal form {
	padding: var(--spacing-ssl);
}

.modal .modal__actions {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: var(--spacing-l) var(--spacing-ssl);
	width: 100%;
	background: var(--gray-1);
	text-align: right;
}

.modal.hide-buttons .modal__actions {
	display: none !important;
}

.modal.center .modal__content {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.modal.center .modal__actions {
	position: relative;
	display: flex;
	justify-content: flex-end;
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.small-modal {
	border-radius: var(--border-radius);
	flex-direction: column;
	padding: var(--spacing-m) 0;
	box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
	background: var(--gray-0);
	width: 300px;
	height: auto;
	max-height: 300px;
	position: absolute;
	border-radius: 3px;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 99;
	top: 0;
	left: 0;
}

form h3 {
	margin: 0 0 var(--spacing-l) 0;
	line-height: 32px;
}

form h3:empty {
	margin: 0;
}

.circle-loader {
	border-radius: 100%;
	margin: 2px;
	border: 3px solid var(--blue-2);
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 25px;
	width: 25px;
	display: inline-block;
	animation: rotate 0.4s linear infinite;
}

.circle-loader.white {
	border: 2px solid #ffffff;
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 15px;
	width: 15px;
}

.skeleton-loader {
	width: 100%;
}

.skeleton-loader > div {
	padding: var(--spacing-m);
	border-radius: var(--border-radius);
	background: linear-gradient(90deg, var(--gray-1), var(--gray-2));
	margin-bottom: var(--spacing-l);
	animation: spotlight 1.5s ease infinite;
	background-size: 200% 200%;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.skeleton-loader > div:nth-child(3n) {
	width: 80%;
}

.skeleton-loader > div:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
	animation: move-light 2s ease-in-out infinite;
}

@keyframes move-light {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(100%);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.input {
	margin-bottom: var(--spacing-sl);
}

.input.error input,
.input.error select {
	border-bottom: solid 1px var(--red-1);
}

.input.error small {
	color: var(--red-1);
	padding-top: var(--spacing-m);
	display: block;
}

.input textarea {
	width: 100%;
	padding: var(--spacing-l);
	border: 1px solid var(--gray-2);
}

datalist {
	width: 100%;
	border: 1px solid var(--gray-2);
}

form .input:last-of-type {
	margin: 0;
}

form .input-horizontal-collection .input:last-of-type {
	margin-bottom: var(--spacing-sl);
	margin-left: var(--spacing-m);
}

form .input-horizontal-collection .input:first-of-type {
	margin-right: var(--spacing-m);
}

.input p {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

.input .label {
	font-weight: 500;
	margin-bottom: var(--spacing-m);
}

.input .label + p {
	color: var(--gray-4);
	margin-bottom: var(--spacing-m);
}

.input input,
.input select {
	padding: var(--spacing-l);
	width: 100%;
	border: none;
	border-bottom: 1px solid var(--gray-2);
	font-size: 14px;
	color: var(--gray-5);
}

.input input:read-only {
	pointer-events: none;
}

.input select:invalid {
	color: var(--gray-3);
}

.input select option {
	color: var(--gray-5);
}

.input input:focus {
	border: none;
	outline: none;
	border-bottom: 1px solid var(--blue-2);
}

.input.filled input {
	border-radius: var(--border-radius);
	background: var(--gray-1);
	border: 1px solid transparent;
}

.input.filled input:disabled {
	border: solid 1px var(--gray-2);
	background: var(--gray-1);
	color: var(--gray-5);
	background: #f0f0f0;
}

.input.filled input:focus {
	border: 1px solid var(--blue-2);
}

input[type='submit'] {
	background: var(--blue-2);
}

input[type='range'] {
	pointer-events: auto;
}

.input p:empty {
	margin: 0;
}

.input small {
	color: var(--gray-4);
}

.select {
	width: 100%;
}

.table {
	background: var(--gray-0);
	border-collapse: collapse;
	border-radius: var(--border-radius);
	width: 100%;
	text-align: left;
	display: flex;
	flex-direction: column;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: var(--spacing-sl);
}

.table header {
	border-bottom: 1px solid var(--gray-2);
}

.table header,
.table main {
	display: flex;
	width: 100%;
	font-size: 14px;
	align-items: center;
}

.table main > span {
	padding: var(--spacing-l);
	margin: 0;
	color: var(--gray-4);
}

.table header > div {
	flex: 1;
	font-weight: 400;
	color: var(--gray-3);
}

.table header .options {
	display: flex;
	justify-content: flex-end;
}

.table main > div {
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid var(--gray-2);
	color: var(--gray-5);
}

.table main {
	flex-direction: column;
}

.table main > div > div {
	flex: 1;
	flex-wrap: wrap;
	word-break: break-all;
}

.table header div {
	align-items: flex-end;
	padding: var(--spacing-m) var(--spacing-l);
}

.table main > div > div {
	padding: var(--spacing-m) var(--spacing-l);
}

.table .tabs {
	width: calc(100% + 30px);
	margin: -10px -15px;
	padding: 0;
}

.table .table__slot {
	padding: var(--spacing-xl);
}

.table .table__slot:empty {
	padding: 0;
}

.table .dropdown {
	padding: 0;
}

.tabs {
	display: flex;
	width: 100%;
	padding-right: 40px;
	border-bottom: 1px solid var(--gray-2);
	background: var(--gray-0);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs li {
	flex: 0 1 200px;
	text-align: center;
}

.tabs li:hover,
.tabs li.active {
	border-bottom: 2px solid var(--blue-2);
}

.tabs li > a {
	padding: var(--spacing-xl);
}

.tabs li a {
	text-decoration: none;
	color: var(--gray-5);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tabs li a i {
	font-size: 18px;
	margin-left: var(--spacing-s);
	opacity: 0;
	transition: 0.15s;
	color: var(--blue-2);
}

.tabs li a i:hover {
	color: var(--blue-2);
}

.tabs li a:hover i {
	opacity: 1;
}

.container {
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	border-radius: var(--border-radius);
	width: 15px;
	height: 15px;
	margin-left: 15px;
	background: var(--gray-2);
}

.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0;
	z-index: 1;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
}

.container input:checked ~ .checkmark {
	background-color: #2196f3;
	border-radius: var(--border-radius);
}

.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.checkbox {
	display: block;
}

.checkbox .checkbox-name {
	padding-left: 5px;
}

.input .checkbox input {
	width: auto;
}

.container input:checked ~ .checkmark:after {
	display: block;
}

.container .checkmark:after {
	left: 6px;
	top: 2px;
	width: 2px;
	height: 7px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.description {
	padding: var(--spacing-l) 0;
}

.description li {
	color: var(--gray-5);
	margin-bottom: var(--spacing-l);
}

.description li span {
	color: var(--gray-4);
	margin-right: var(--spacing-s);
}

.button {
	padding: var(--spacing-m) var(--spacing-xl);
	background: var(--blue-2);
	color: var(--gray-0);
	border-radius: var(--border-radius);
	text-decoration: none;
	display: inline-flex;
	border: 1px solid var(--blue-2);
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	line-height: 20px;
	position: relative;
	white-space: nowrap;
	text-align: center;
	align-items: center;
}

.button i {
	font-size: 18px;
	user-select: none;
	margin-right: var(--spacing-s);
	position: relative;
	top: 1px;
}

.button.small {
	padding: 0 var(--spacing-m);
	font-size: 12px;
}

.button.small i {
	font-size: 12px;
}

.button.medium {
	padding: var(--spacing-s) var(--spacing-m);
	font-size: 13px;
}

.button.medium i {
	font-size: 13px;
}

.button.large {
	padding: calc(var(--spacing-l) / 1.3) var(--spacing-sl);
}

.button + .button {
	margin-right: var(--spacing-m);
}

.button.medium + .button.medium {
	margin-right: var(--spacing-s);
}

.button.white {
	background: var(--gray-0);
	color: var(--blue-2);
	border: 1px solid var(--blue-2);
}

.button.white i {
	color: var(--blue-2);
}

.button.red {
	background: var(--red-1);
	color: var(--gray-0);
	border: 1px solid var(--red-1);
}

.button.yellow {
	background: var(--yellow-1);
	border: 1px solid var(--yellow-1);
	color: var(--gray-0);
}

.button.transparent {
	background: transparent;
	color: var(--blue-2);
	border: none;
}

.button.block {
	width: 100%;
	text-align: center;
	justify-content: center;
}

.submit-loading {
	position: relative;
	margin: auto;
}

.button.loading::before,
.submit-loading::before {
	content: '';
	border-radius: 100%;
	border: 2px solid var(--gray-0);
	border-bottom-color: transparent;
	border-right-color: transparent;
	height: 15px;
	width: 15px;
	display: inline-block;
	-webkit-animation: rotate 0.4s linear infinite;
	animation: rotate 0.4s linear infinite;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 99;
	margin: auto;
}

.submit-loading input,
.button.loading {
	color: transparent;
}

.button.loading {
	pointer-events: none;
	user-select: none;
}

.button:focus {
	outline: none;
}

.button.download {
	justify-content: center;
	align-self: baseline;
}

.button.download i {
	font-size: 18px;
}

.button.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.call-button {
	padding: 13px;
	border-radius: 100%;
	background: var(--blue-2);
	color: var(--gray-0);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	display: inline-block;
	margin-right: var(--spacing-s);
	font-size: 0;
	user-select: none;
	cursor: pointer;
	position: relative;
}

.call-button.tooltip-hidden::before,
.call-button.tooltip-hidden::after {
	display: none;
}

.call-button:not(:last-child) {
	margin-right: 10px;
}

.call-button > i {
	color: var(--gray-0);
}

.call-button.active {
	background: var(--gray-0);
}

.call-button.active > i {
	color: var(--blue-2);
}

.call-button.active:hover {
	background: var(--blue-2);
}

.call-button.active:hover > i {
	color: var(--gray-0);
}

.call-button.end {
	background: var(--red-1);
	padding: var(--spacing-l) var(--spacing-sl);
	border-radius: 30px;
}

.call-button.end:hover {
	background: var(--red-2);
}

.call-button.end i {
	color: var(--gray-0);
}

.call-button.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.call-button > .list-group {
	position: absolute;
	bottom: calc(100% + 10px);
	right: 0;
}

.participant {
	width: 150px;
}

.participant header {
	padding: var(--spacing-s) var(--spacing-m);
	display: flex;
	align-items: center;
	background: var(--gray-0);
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
	position: relative;
	z-index: 1;
}

.participant header > div {
	display: flex;
	align-items: center;
}

.participant header p {
	padding-top: 0;
	margin: 0;
	flex: 1;
	font-size: 14px;
}

.participant .mic_off-icon {
	color: red;
	font-size: 20px;
}

.participant header i {
	margin: 0;
}

.participant .dropdown {
	padding: 0;
	margin: 0;
}

.participant main {
	position: relative;
	display: flex;
	height: 80px;
	width: 100%;
	margin-bottom: var(--spacing-m);
	background: var(--gray-1);
	text-align: center;
	align-items: center;
	justify-content: center;
}

.participant main img {
	width: 80px;
	border-radius: 100%;
	padding: var(--spacing-m);
}

.empty-state {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: var(--spacing-xxl);
	text-align: center;
	width: 350px;
	align-items: center;
}

.empty-state h1 {
	font-size: 24px;
	font-weight: 300;
	padding: var(--spacing-xxl) 0;
}

.label-box {
	display: flex;
	flex-direction: column;
	color: var(--gray-4);
	background: var(--gray-1);
	padding: var(--spacing-m);
}

.label-box,
.label-box i {
	font-size: 16px;
}

.label-box > div {
	display: flex;
	align-items: center;
	margin-bottom: var(--spacing-m);
}

.label-box > div:last-of-type {
	margin: 0;
}

.label-box > div:nth-child(2) {
	margin-left: var(--spacing-xxl);
}

.label-box > div:nth-child(3) {
	margin-left: var(--spacing-sssl);
}

.label-box > div:nth-child(4) {
	margin-left: calc(var(--spacing-sssl) * 1.5);
}

.label-box i {
	color: var(--gray-4);
	margin-right: var(--spacing-s);
}

.pagination-container {
	text-align: right;
	padding-top: var(--spacing-l);
}

.pagination-container .pagination {
	width: 350px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: var(--spacing-l);
}

.pagination-container .pagination > * {
	display: inline-block;
	padding-left: var(--spacing-xl);
}

.pagination-container .pagination span {
	font-size: 14px;
}

.pagination-container .pagination .pagination-controls button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding-top: var(--spacing-s);
}

.pagination-container .pagination .pagination-controls button i {
	font-size: 18px;
	color: var(--gray-4);
}

.pagination-container .custom-select__control {
	width: 60px;
}

.assign-dropdown {
	width: 300px;
	padding-top: var(--spacing-m);
	padding-bottom: var(--spacing-m);
}

/* custom select*/

.custom-select__control {
	width: 100%;
	font-size: 14px;
	cursor: pointer !important;
	border: 1px solid var(--gray-2);
	border-radius: 3px;
}

.custom-select__control .custom-select__placeholder {
	color: var(--gray-5);
}

.custom-select__indicator-separator {
	display: none;
}

.custom-select__menu-list > div {
	font-size: 14px;
	padding-top: 7px;
	padding-bottom: 7px;
}

/*form custom select*/

.error .form-custom-select__control {
	border-color: var(--red-1) !important;
}

.form-custom-select__control {
	padding: var(--spacing-m);
	width: 100%;
	font-size: 14px;
	color: var(--gray-5);
	border-style: none !important;
	border-radius: 0px !important;
	border-bottom-style: solid !important;
	border-color: var(--gray-2) !important;
}

.form-custom-select__control .custom-select__placeholder {
	color: var(--gray-5);
}

.form-custom-select__indicator-separator {
	display: none;
}

.form-custom-select__menu-list > div {
	font-size: 14px;
	padding-top: 7px;
	padding-bottom: 7px;
}

[data-tooltip] {
	position: relative;
}

[data-tooltip]::before,
[data-tooltip]::after {
	opacity: 0;
	visibility: hidden;
	transition: 0.1s;
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
	opacity: 1;
	visibility: visible;
}

[data-delay]:hover::before,
[data-delay]:hover::after {
	transition-delay: 0.7s;
}

[data-tooltip]::before {
	content: attr(data-tooltip);
	position: absolute;
	padding: 5px 10px;
	background: var(--gray-5);
	color: var(--gray-0);
	top: 50%;
	transform: translateY(-50%);
	white-space: nowrap;
	left: calc(100% + 4px);
	font-size: 12px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: var(--border-radius);
	font-weight: 500;
	max-width: 300px;
	text-transform: none;
	font-family: var(--font-family);
}

[data-tooltip]::after {
	content: '';
	background: var(--gray-5);
	padding: 4px;
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
}

[data-position='bottom']::before {
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	z-index: 1;
}

[data-position='bottom']::after {
	top: calc(100% - 3px);
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
	position: absolute;
	z-index: 1;
}

[data-position='top']::before {
	bottom: calc(100% + 6px);
	top: auto;
	left: 50%;
	transform: translateX(-50%);
}

[data-position='top']::after {
	bottom: calc(100% + 3px);
	top: auto;
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
}

[data-position='left']::before {
	bottom: auto;
	top: 50%;
	right: calc(100% + 4px);
	left: auto;
	transform: translateY(-50%);
}

[data-position='left']::after {
	bottom: auto;
	top: 50%;
	left: auto;
	transform: translateY(-50%) rotate(45deg);
	right: 100%;
}

.alert {
	width: 100%;
	padding: var(--spacing-m);
	margin: var(--spacing-m) 0;
	font-size: 14px;
	border-radius: var(--border-radius);
	display: flex;
	flex-direction: row;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.alert span {
	flex: 1;
	text-align: center;
}

.alert .close-icon {
	cursor: pointer;
}

.alert.success {
	background-color: var(--green-3);
	color: var(--gray-4);
}

.alert.success span {
	color: var(--gray-4);
}

.alert.error {
	border: solid 1px var(--pink-2);
	background-color: var(--pink-1);
	color: var(--red-1);
}

.alert.error span {
	color: var(--red-1);
}

.alert.neutral {
	border: none;
	background-color: var(--gray-1);
	color: var(--gray-4);
}

.alert.neutral span {
	color: var(--gray-4);
}

.alert.dark {
	border: none;
	background-color: var(--gray-5);
	color: var(--gray-0);
}

.alert.dark span {
	color: var(--gray-0);
}

.alert.show {
	opacity: 1;
	visibility: visible;
}

.alert.fixed {
	position: fixed;
	transform: translateY(100%);
	left: var(--spacing-m);
	bottom: var(--spacing-m);
	opacity: 0;
	z-index: 999;
	width: auto;
}

.alert.fixed.show {
	animation: showAlert 3s;
}

.alert.persist,
.alert.fixed.persist {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
	right: var(--spacing-m);
	left: initial;
	bottom: var(--spacing-xxl);
}

.alert.top {
	position: fixed;
	left: 50%;
	top: 10px;
	transform: translate(-50%, 0%);
	height: 70px;
	max-width: 600px;
	z-index: 999;
}

@keyframes showAlert {
	5% {
		opacity: 1;
		transform: translateY(0);
	}
	90% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(100%);
	}
}

.avatar {
	width: 64px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	color: var(--gray-4);
}

.avatar.small {
	width: 32px;
	height: 32px;
}

.avatar.medium {
	width: 80px;
	height: 80px;
}

.avatar.large {
	width: 128px;
	height: 128px;
}

.toggle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.8);
	border-radius: var(--border-radius);
	overflow: hidden;
}

.toggle > a {
	padding: var(--spacing-m) var(--spacing-l);
	font-size: 14px;
	flex: 1;
	cursor: pointer;
	text-decoration: none;
	color: var(--gray-3);
	user-select: none;
}

.toggle.toggle-on > a:first-of-type,
.toggle.toggle-off > a:last-of-type {
	background: var(--blue-2);
	color: var(--gray-0);
	box-shadow: none;
	border-radius: 30px;
	pointer-events: none;
}

.toggle-feed {
	position: absolute;
	width: 150px;
	text-align: center;
	border-radius: 30px;
	line-height: 15px;
	user-select: none;
}

/*************
    TILT
*************/

.tilt_container {
	width: 150px;
	position: absolute;
	display: flex;
	align-items: center;
	flex-direction: column;
	top: 50px;
	z-index: 999;
}

.tilt_figure {
	display: inline-block;
	padding: 6px;
	background: var(--gray-2);
	border-radius: 50%;
	background: url(https://solprodmediastorage.blob.core.windows.net:443/static-assets/healthcare-system/full70.svg) rgba(255, 255, 255, 0.7) no-repeat;
	box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.7);
	overflow: hidden;
}

.tilt__zoom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 14px;
	background: rgba(255, 255, 255, 0.8);
	height: 35px;
	width: 100%;
	user-select: none;
	border-radius: 30px;
	overflow: hidden;
}

.tilt__zoom--text {
	padding: 0;
	margin: 0;
	font-size: 14px;
	color: var(--gray-4);
}

.tilt__zoom--in,
.tilt__zoom--out {
	height: 100%;
	width: 35px;
	border: none;
	cursor: pointer;
}

.tilt__zoom--in i,
.tilt__zoom--out i {
	font-size: 16px;
	color: var(--gray-4);
}

.tilt__zoom--in:focus,
.tilt__zoom--out:focus {
	outline: none;
}

.tilt__zoom button {
	border-radius: 30px;
	width: 37px;
	font-size: 0;
	padding: 0;
}

figure {
	position: relative;
	width: 138px;
	height: 138px;
	border-radius: 50%;
	transform: rotate(45deg);
	margin: 0;
}

figure figcaption {
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
	z-index: 10;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

figcaption img {
	width: 100%;
	user-select: none;
}

/*************
    TILT BTN
*************/

.btn {
	width: 71px;
	height: 72px;
	position: absolute;
	transform-origin: 100% 100%;
	cursor: pointer;
	user-select: none;
}

.btn::after {
	content: '';
	position: absolute;
	z-index: 2;
	width: 100px;
	height: 100px;
	display: none;
}

.btn.btn_up::after {
	background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat;
	top: -32px;
	left: -32px;
	transform: rotate(-135deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_up:hover::after {
	display: block;
}

.btn.btn_right::after {
	background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat;
	top: -32px;
	left: 3px;
	transform: rotate(-45deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_right:hover::after {
	display: block;
}

.btn.btn_left::after {
	background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat;
	top: 4px;
	left: -32px;
	transform: rotate(135deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_left:hover::after {
	display: block;
}

.btn.btn_down::after {
	background: url(https://static.solaborate.com/healthcare-system/ptz-hover-bg.svg) no-repeat;
	top: 4px;
	left: 3px;
	transform: rotate(45deg);
	opacity: 0.5;
	visibility: visible;
}

.btn.btn_down:hover::after {
	display: block;
}

.btn:nth-of-type(2) {
	right: 0;
}

.btn:nth-of-type(3) {
	bottom: 0;
	left: 0;
}

.btn:last-of-type {
	right: 0;
	bottom: 0;
}

.btn:before {
	content: '';
	opacity: 0.6;
	position: absolute;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.64 5.2'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%236d6d6d;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M14.89,4.45,8.9,1A2.22,2.22,0,0,0,6.75,1l-6,3.42'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-size: 16px 16px;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
}

.btn:first-of-type:before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
}

.btn:nth-of-type(2):before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
}

.btn:nth-of-type(3):before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-135deg);
}

.btn:last-of-type:before {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(135deg);
}

.tilt__icons {
	fill: gray;
}

.reactLink {
	text-decoration: none;
}

.loader__container {
	position: absolute;
	top: 0;
	left: 0;
	background: #212121;
	height: 100%;
	width: 100%;
}

.center-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	display: inline-grid;
	grid-gap: 15px;
	justify-items: center;
	align-items: center;
	transform: translate(-50%, -50%);
}

.center-loader p {
	color: white;
	font-size: 14px;
}

/*
    Incoming call Patient
*/

.incoming-calls {
	width: 100vw;
	background-color: rgba(1, 83, 182, 0.9);
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
}

.incoming-calls > div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.incoming-call-img {
	border-radius: 50%;
	animation: pulse 2s infinite;
}

.incoming-call-text {
	color: white;
	font-size: 24px;
}

.incoming-calls.stream-permissions {
	background: rgba(0, 0, 0, 0.9);
}

.incoming-calls.stream-permissions > div {
	text-align: left;
}

.incoming-calls.stream-permissions > div > div {
	width: 600px;
	max-width: 100%;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 rgba(255, 255, 255, 0.5);
	}
	100% {
		box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
	}
}

.stream-permissions h2,
.stream-permissions h3,
.stream-permissions p {
	color: white;
}

.stream-permissions p {
	margin: 0;
	padding: 0;
}

.stream-permissions h2,
.stream-permissions h3 {
	margin: 0 0 10px;
}

.users-view .assign-btn {
	background: var(--gray-1);
	padding: 5px 10px;
	font-size: 12px;
	font-weight: 500;
	color: var(--gray-5);
	border-radius: var(--border-radius);
	cursor: pointer;
	display: inline-block;
	word-break: normal;
}

.users-view .assign-btn.unassigned {
	border: solid 1px var(--gray-2);
}

.users-view .assign-btn.unassigned span {
	display: flex;
	align-items: center;
	white-space: nowrap;
	font-size: 12px;
	text-decoration: none;
	font-weight: bold;
}

.users-view .assign-btn.unassigned span i {
	padding-right: 10px;
	padding-right: 5px;
	font-size: 22px;
}

/* Remove region styling*/

.add-remove-region {
	position: relative;
}

.add-remove-region input {
	padding-right: calc(var(--spacing-sl) + var(--spacing-s));
}

.add-remove-region i {
	position: absolute;
	right: 15px;
	top: 15px;
	color: var(--gray-4);
	cursor: pointer;
}

.create-hs input + small,
.create-hs .add-remove-region ~ p {
	color: var(--red-1);
	font-size: 14px;
	font-weight: normal;
}

.create-hs-success-icon {
	padding: 20px;
	color: var(--gray-0);
	font-size: 50px;
	border-radius: 100%;
	background: var(--green-1);
}

.create-hs-success {
	display: flex;
	flex-direction: column;
	height: 100vh;
	align-items: center;
	justify-content: center;
}

.create-hs-success > * {
	margin-bottom: var(--spacing-sssl);
}

.call-logs-view .call-properties span {
	display: block;
	width: 100%;
}

.profile-picture {
	height: 30px;
	object-fit: cover;
	width: 30px;
	border-radius: 100%;
	margin-right: var(--spacing-l);
}

.call-correlation-info {
	position: fixed;
	left: var(--spacing-m);
	bottom: 93px;
	background: rgba(1, 1, 1, 0.5);
	color: white;
	padding: 4px !important;
	border-radius: 4px;
	opacity: 0.4;
	z-index: 100000;
	font-size: 13px;
}

.call-correlation-info.out-of-call {
	bottom: 10px;
}

.call-correlation-info > span {
	display: block;
	color: white;
}

.call-correlation-info span.correlation-id {
	color: white;
}

.call-correlation-info span .copy-to-clipboard {
	cursor: pointer;
}

.call-correlation-info span .copy-to-clipboard i {
	color: #fff;
	font-size: 16px;
	margin-left: 5px;
}

.build-number {
	position: fixed;
	bottom: 0;
	right: 0;
	background: white;
	padding: 4px 8px;
	font-size: 13px;
	box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	color: #333;
}

@media (max-height: 500px) {
	.modal.center .modal__content {
		max-height: 100vh;
	}
}

.pool-items {
	margin-bottom: 30px;
}

.pool-item-draggable {
	position: relative;
	z-index: 99999;
	border: solid 1px var(--gray-2);
	background-color: rgb(239, 239, 239);
	padding: 10px 15px;
	display: flex;
	margin-bottom: 10px;
	color: var(--gray-6);
}

.pool-item-draggable span {
	padding-right: 10px;
	cursor: move;
}

.cursor-pointer {
	cursor: pointer;
}

.blank-button {
	padding: 0;
	background: none;
	border: none;
}

.mr-20 {
	margin-right: 20px;
}

.mr-5 {
	margin-right: 5px;
}

.active-configuration {
	background-color: #d0f0c0;
}

.deleted-configuration {
	background-color: rgba(225, 0, 0, 0.25);
}

.old-configuration {
	background-color: #ffcccb;
}

.table-badges {
	text-align: center;
	margin: 15px;
}

.active-directory-tabs.tabs {
	height: 45px;
	box-shadow: none;
	margin-top: 5px;
}

.active-directory-tabs.tabs li span {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.configurations-back-btn {
	display: flex;
	cursor: pointer;
	margin-bottom: 15px;
}

.users-list-wrapper .table main > div > div {
	word-break: break-word;
}

.assigned-devices-list > p.pool-device {
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray-2);
	margin-bottom: 5px;
	color: var(--gray-5);
	font-size: 14px;
}
